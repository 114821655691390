/**
 * Класс хеллпер для ajax форм
 * Замена MetaFormHandler
 * По дефолту ожидает такой же ответ от сервера как и MetaFormHandler
 *
 * Параметры в options:
 * errorClass - класс который будет присвоен input если он не корректно заполнен
 * fieldLineClass - класс контейнер для поля, необходим для отображения ошибки относящейся полю рядом с полем
 * messageBoxSelector - селектор по которому находится контейнер для отображения сообщений
 * showErrorAfterInput - показывать ли сообщение ошибки рядом с полем или помещать в общее сообщение
 * unholdFormAfterSuccess - разморозить форму после успешной отправки формы
 *
 * @param {HTMLFormElement} form объект формы
 * @param {Object} options список параметров
 * @constructor
 */
function TurboForm(form, options) {
    if (form && form instanceof HTMLFormElement) {
        this.form = form;
    } else {
        throw 'form должен быть HTMLFormElement';
    }

    if (!this.form.action) {
        throw 'У формы нет атрибута action';
    }

    if (!this.form.attributes.name) {
        throw 'У формы нет атрибута name';
    }

    this.errorClass = 'form__error';
    this.fieldLineClass = 'form_group';
    this.messageBoxSelector = '.form_alert';
    this.errorBoxSelector = '.form_alert';
    this.errorMsgClass = 'turbo_error_field_msg';
    this.showErrorAfterInput = false;
    this.unholdFormAfterSuccess = true;
    this.response = null;

    if (options) {
        for (var prop in options) {
            if ([
                    'errorClass', 'fieldLineClass', 'messageBoxSelector',
                    'errorBoxSelector', 'errorMsgClass', 'showErrorAfterInput',
                    'unholdFormAfterSuccess'
                ].indexOf(prop) >= 0
            ) {
                this[prop] = options[prop];
            }
        }
    }

    this.messageBox = this.form.querySelector(this.messageBoxSelector);
    this.errorBox = this.form.querySelector(this.errorBoxSelector);

    this.fields = this.form.querySelectorAll('input, select, textarea');
    this.buttons = this.form.querySelectorAll('button');

    this.preloader = this.form.querySelector('.js-preloader');

    var $this = this;
    this.form.addEventListener('submit', function (e) {
        e.preventDefault();
        $this.onSubmit(e);
    });
}

TurboForm.prototype.beforeSend = function () {

};

TurboForm.prototype.showPreloader = function () {
    if (this.preloader) {
        this.preloader.style.display = 'block';
    }
};

TurboForm.prototype.hidePreloader = function () {
    if (this.preloader) {
        this.preloader.style.display = 'none';
    }
};

TurboForm.prototype.reset = function () {
    this.unHoldForm();
    this.hidePreloader();
    this.hideOldErrors();
    if (this.messageBox) {
        this.messageBox.style.display = 'none';
    }
    if (this.errorBox) {
        this.errorBox.style.display = 'none';
    }
    this.form.reset();
};

TurboForm.prototype.holdForm = function () {
    this.fields.forEach(function (field) {
        field.disabled = true;
    });
    this.buttons.forEach(function (button) {
        button.disabled = true;
    });
};

TurboForm.prototype.unHoldForm = function () {
    this.fields.forEach(function (field) {
        field.disabled = false;
    });
    this.buttons.forEach(function (button) {
        button.disabled = false;
    });
};

/**
 * Подсвечиваем поля и отображаем ошибки
 */
TurboForm.prototype.showErrors = function () {
    var self = this;
    this.fields.forEach(function (field) {
        if (field.attributes.hasOwnProperty('name')) {
            var name = field.attributes.name.value;
            if (self.errors.hasOwnProperty(name)) {
                field.classList.add(self.errorClass);
                if (self.fieldLineClass && self.showErrorAfterInput) {
                    var parent = field.parentElement;
                    while (parent && !parent.classList.contains(self.fieldLineClass)) {
                        parent = parent.parentElement;
                    }
                    if (parent) {
                        self.showErrorForInput(parent, field, name);
                    }
                }
            }
        }
    });
};

/**
 * Показываем ошибку для поля рядом с полем
 * @param container - это контейнер для этого поля в который будем помещать сообщение
 * @param input - сам инпут
 * @param filedName - имя инпута
 */
TurboForm.prototype.showErrorForInput = function (container, input, filedName) {
    var p = document.createElement('p');
    p.classList.add(this.errorMsgClass);
    p.textContent = typeof this.errors[filedName] === "object" ? this.errors[filedName].message : this.errors[filedName];
    container.appendChild(p);
};

/**
 * Удаляем все сообщения
 */
TurboForm.prototype.hideErrorsForInputs = function () {
    var labels = this.form.querySelectorAll('.' + this.errorMsgClass);
    labels.forEach(function (item) {
        item.parentElement.removeChild(item);
    });
};

/**
 * Убираем все ошибки
 */
TurboForm.prototype.hideOldErrors = function () {
    var form = this;
    this.fields.forEach(function (field) {
        field.classList.remove(form.errorClass);
    });
    if (this.messageBox) {
        this.messageBox.style.display = '';
    }
    if (this.errorBox) {
        this.errorBox.style.display = '';
    }
    if (this.showErrorAfterInput) {
        this.hideErrorsForInputs();
    }
};

/**
 * Отображение сообщения при благоприятном исходе
 * @param message
 */
TurboForm.prototype.showSuccessMessage = function (message) {
    if (this.messageBox) {
        if (message) {
            this.messageBox.textContent = message;
        } else {
            this.messageBox.textContent = 'Сообщение успешно отправлено';
        }
        this.messageBox.style.display = 'block';
    }
};

/**
 * Отображение общего сообщения об ошибке на форме
 * @param message
 */
TurboForm.prototype.showErrorMessage = function (message) {
    if (this.errorBox) {
        if (!this.showErrorAfterInput) {
            for (var name in this.errors) {
                if (this.errors.hasOwnProperty(name)) {
                    message += (message ? ', ' : '') + (typeof this.errors[name] === "object" ? this.errors[name].message : this.errors[name]);
                }
            }
        }
        this.errorBox.textContent = message;
        this.errorBox.style.display = 'block';
    }
};

/**
 * От сервера получен ответ, пытаемся его разобрать
 * @param resp
 */
TurboForm.prototype.onSuccess = function (resp) {
    try {
        var json = JSON.parse(resp);
        this.response = json;
        var status = json[0];
        var message = json[1];
        this.hideOldErrors();
        if (status.ok) {
            if (this.unholdFormAfterSuccess) {
                this.unHoldForm();
            }
            this.showSuccessMessage(message);
        } else {
            this.unHoldForm();
            if (status.hasOwnProperty('errors')) {
                this.errors = status.errors;
                this.showErrors();
            }
            this.showErrorMessage(message);
        }
    } catch (e) {
        alert('Произошла ошибка. Обратитесь к администратору сайта.');
        console.log(e);
    }
};

/**
 * Получили от сервера ошибку (код ответа >= 400), пытаемся понять в чем дело
 * @param xhr
 */
TurboForm.prototype.onError = function (xhr) {
    if (xhr.responseType === 'application/json') {
        try {
            this.response = JSON.parse(xhr.response);
        } catch (e) {
            console.log('Говорит что json, а сам не валидный');
            console.log(e);
        }
    }
    var message = this.response.message ? this.response.message : 'Произошла ошибка. Обратитесь к администратору сайта.';
    this.showErrorMessage(message);
};

TurboForm.prototype.afterSend = function () {

};

/**
 * Формирование и отправка сообщения на сервер.
 * @param e
 */
TurboForm.prototype.onSubmit = function (e) {
    this.showPreloader();
    var data = new FormData(this.form);
    var xhr = new XMLHttpRequest();
    var form = this;
    var url = this.form.action + this.form.attributes.name.value + '/';

    xhr.open('POST', url);
    xhr.onload = function () {
        // TODO: отрефакторить что бы у функций был одинаковый вход
        // вынести сюда парсинг json
        // добавить {after,before}{Success,Error} функции
        if (this.status >= 200 && this.status < 400) {
            form.onSuccess(this.response)
        } else {
            form.onError(this);
        }
        form.afterSend();
        form.hidePreloader();
    };

    xhr.onerror = function () {
        form.onError(this);
        form.hidePreloader();
    };

    xhr.setRequestHeader('X-REQUESTED-WITH', 'XMLHttpRequest');

    this.beforeSend();
    this.holdForm();
    xhr.send(data);
};

